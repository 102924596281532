<template>
  <div class="p-topHeroSlider">
    <transition name="a-topHeroSlider" mode="in-out" type="transition">
      <div
        :key="currentIndex"
        class="p-topHeroSlider__item"
        :style="`background-image: url(${slideItems[currentIndex]})`"
      />
    </transition>
    <span v-if="!hasVideo" ref="indicator" class="p-topHeroSlider__indicator" />
  </div>
</template>

<script>
import { gsap } from 'gsap'

const SliderListMap = {
  shops: [
    '/img/top/visual_hero01.jpg',
    '/img/top/visual_hero02.jpg',
    '/img/top/visual_hero03.jpg',
    '/img/top/visual_hero04.jpg',
  ],
  schools: ['/img/top/schools_visual_hero01.jpg'],
  hospitals: ['/img/top/hospitals_visual_hero01.jpg'],
}

export default {
  name: 'PTopHeroSlider',
  props: {
    hasVideo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentIndex: 0,
      timer: false,
    }
  },
  computed: {
    slideItems() {
      return SliderListMap[this.portalId] || SliderListMap.shops
    },
    nextIndex() {
      const nextIndex = this.currentIndex + 1
      return nextIndex === this.slideItems.length ? 0 : nextIndex
    },
    indicator() {
      return this.$refs.indicator
    },
  },
  mounted() {
    if (this.slideItems.length < 2) return
    this.start()
  },
  beforeDestroy() {
    this.killIndicatorTween()
    if (this.timer) clearTimeout(this.timer)
  },
  methods: {
    start() {
      if (this.hasVideo) this.startTimer()
      else this.startIndicator()
    },
    startTimer() {
      this.timer = setTimeout(() => {
        this.changeSlide()
        this.timer = setTimeout(() => {
          this.startTimer()
        }, 1000)
      }, 10000)
    },
    startIndicator() {
      this.killIndicatorTween()
      gsap.fromTo(
        this.indicator,
        {
          scaleX: 0,
          transformOrigin: 'left',
        },
        {
          scaleX: 1,
          duration: 10,
          ease: 'linear',
          onComplete: () => {
            this.changeSlide()
            this.resetIndicator()
          },
        }
      )
    },
    resetIndicator() {
      this.killIndicatorTween()
      gsap.fromTo(
        this.indicator,
        {
          scaleX: 1,
          transformOrigin: 'right',
        },
        {
          scaleX: 0,
          duration: 1,
          ease: 'power4.out',
          onComplete: () => {
            this.startIndicator()
          },
        }
      )
    },
    killIndicatorTween() {
      gsap.killTweensOf(this.indicator)
    },
    changeSlide() {
      this.currentIndex = this.nextIndex
    },
  },
}
</script>
