<template>
  <IndexPresentation :shop-list="shopList" :notice-list="noticeList" :sensorCountList="sensorCountList"/>
</template>

<script>
import { requestIndexData } from '@/assets/request'
import { requestTotalSensorData } from '@/assets/request'
import IndexPresentation from '@/components/top/IndexPresentation.vue'
import { postAccessLog } from '@/assets/request/api/postAccessLog'
import { getNoticeCsv } from '@/assets/request/csv/getNoticeCsv'

const RequestPortalIdMap = {
  shops: 'shop',
  schools: 'school',
  hospitals: 'hospital',
}

export default {
  name: 'Index',
  components: {
    IndexPresentation,
  },
  data() {
    return {
      shopList: [],
      noticeList: [],
      sensorCountList: {}
    }
  },
  async beforeRouteUpdate(to, from, next) {
    if (
      to.query.status === 'signout' ||
      to.params.portalId !== from.params.portalId
    ) {
      const response = await requestIndexData(
        RequestPortalIdMap[to.params.portalId]
      )
      const sensorCountListResponse = await requestTotalSensorData()

      this.setData(response)
      this.setSensorCountList(sensorCountListResponse)

      next()

      if (to.query.status === 'signout') {
        this.$router.replace({
          name: 'Index',
          params: { portalId: to.params.portalId },
        })
        this.$toasted.show(this.$t('ログアウトしました'))
      }
      return
    }

    next()
  },
  async beforeRouteEnter(to, _from, next) {
    const response = await requestIndexData(
      RequestPortalIdMap[to.params.portalId]
    )
    const sensorCountListResponse = await requestTotalSensorData()
    const result = await getNoticeCsv()
    if (result.isError) {
      // console.log('not found notice file.')
    }

    next((mv) => {
      mv.setData(response)
      mv.setSensorCountList(sensorCountListResponse)
      if (!result.isError) {
        mv.setNoticeList(result.data.list)
      } else {
        mv.setNoticeList([])
      }

      if (to.query.status === 'signout') {
        mv.$router.replace({
          name: 'Index',
          params: { portalId: mv.portalId },
        })
        mv.$toasted.show(mv.$t('ログアウトしました'))
      }
    })
  },
  methods: {
    setData(shopList) {
      if (!shopList.isError) {
        this.shopList = shopList.data.list
      }
    },
    setNoticeList(list) {
      this.noticeList = list
    },
    setSensorCountList(sensorCountList) {
      if (!sensorCountList.isError) {
        this.sensorCountList = sensorCountList.data._rawResponse.data
      }
    },
  },
  mounted(){
    postAccessLog('Top', 'top_' + this.$route.params.portalId, location.href)
  },
}
</script>
