<template>
  <div class="p-topHeroYouTubeSlider">
    <div class="swiper-container p-topHeroYouTubeSlider__container">
      <ul class="swiper-wrapper p-topHeroYouTubeSlider__list">
        <li
          v-for="(videoId, index) in videoIdList"
          :key="videoId"
          class="swiper-slide p-topHeroYouTubeSlider__item"
        >
          <PYouTubePlayer
            :video-id="videoId"
            :size="size"
            :played="playState[index]"
            @change="handleChangePlayed(index, $event)"
          />
        </li>
      </ul>
    </div>
    <div v-if="hasSlider" class="p-topHeroYouTubeSlider__controls">
      <button
        ref="prevButton"
        class="p-topHeroYouTubeSlider__button p-topHeroYouTubeSlider__button--prev"
      />
      <ol
        ref="pagination"
        class="swiper-pagination p-topHeroYouTubeSlider__pagination"
      />
      <button
        ref="nextButton"
        class="p-topHeroYouTubeSlider__button p-topHeroYouTubeSlider__button--next"
      />
    </div>
  </div>
</template>

<script>
import PYouTubePlayer from '@/components/common/PYouTubePlayer.vue'
import Swiper from 'swiper'

export default {
  name: 'PTopHeroYouTubeSlider',
  components: {
    PYouTubePlayer,
  },
  beforeMount() {
    this.playState = this.videoIdList.concat().fill(false)
  },
  mounted() {
    if (!this.hasSlider) return
    this.initSlider()
  },
  beforeDestroy() {
    this.destroySlider()
  },
  props: {
    videoIdList: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: '16p9',
    },
  },
  data() {
    return {
      playState: [],
      swiper: undefined,
    }
  },
  computed: {
    sliderEl() {
      return this.$refs.slider
    },
    prevButtonEl() {
      return this.$refs.prevButton
    },
    nextButtonEl() {
      return this.$refs.nextButton
    },
    paginationEl() {
      return this.$refs.pagination
    },
    hasSlider() {
      return this.videoIdList.length > 1
    },
  },
  methods: {
    initSlider() {
      this.swiper = new Swiper('.swiper-container', {
        pagination: {
          el: this.paginationEl,
          type: 'bullets',
          bulletElement: 'li',
          clickable: true,
        },
        navigation: {
          nextEl: this.nextButtonEl,
          prevEl: this.prevButtonEl,
        },
        on: {
          slideChange: () => {
            this.playState = this.playState.concat().fill(false)
          },
        },
      })
    },
    destroySlider() {
      if (!this.swiper) return
      this.swiper.destroy()
      this.swiper = undefined
    },
    handleChangePlayed(index, nextPlayedFlag) {
      const nextState = this.playState.concat()
      nextState[index] = nextPlayedFlag

      console.log(nextPlayedFlag)

      this.playState = nextState
    },
  },
}
</script>
