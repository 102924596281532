import { parse } from 'csv-parse/lib/sync'

const NoticeColumnName = [
  'notice',
  'noticeEnglish',
]

export class NoticeViewModel {
  constructor(text) {
    /** @type {string} */
    this._raw = text
  }

  /** @type {import('../types/NoticeList').NoticeList} */
  get list() {
    const records = parse(this._raw, {
      trim: true,
      skip_empty_lines: true,
      columns: (header) => header.map((_, index) => NoticeColumnName[index]),
    })
    return records.reduce(
      (
        result,
        {
          notice,
          noticeEnglish,
          ...data
        }) => {
        result.push(
          {
            notice_ja: notice,
            notice_en: noticeEnglish,
          }
        )
        return result
      },
      []
    )
  }
}
