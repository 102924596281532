<template>
  <div class="p-topHero" :class="{ 'p-topHero--hasVideo--adjustment': hasVideo }" >
    <div class="p-topHero__container">
      <div class="p-topHero__inner">
        <div class="p-topHero__content">
          <h1
            class="p-topHero__catch"
            v-html="$t(`${portalId}.施設の環境をみつめよう。`)"
          />
          <p
            class="p-topHero__lead"
            v-html="
              $t(
                `${portalId}.現代のニューノーマルに対応した衛生的で安心な施設をご利用いただくため施設の信頼性（トラスト）情報をリアルタイムに見える化します。`
              )
            "
          />
        </div>
        <PTopHeroVideo v-if="hasVideo" class="p-topHero__video" />
        <form class="p-topHero__search" @submit="handleSubmitSearchForm">
          <fieldset class="p-topHero__searchField">
            <div class="field field--addons">
              <div class="control control--expanded">
                <input
                  class="input"
                  type="text"
                  :placeholder="$t(`${portalId}.店舗名 / 施設名で探す`)"
                  v-model="keyword"
                />
              </div>
              <div class="control control--expanded">
                <input
                  class="input"
                  type="text"
                  :placeholder="$t('場所で探す')"
                  v-model="address"
                />
              </div>
              <div class="control">
                <button class="p-topHero__searchSubmit button" type="submit">
                  <span class="p-topHero__searchSubmitIcon icon icon--search" />
                  <span class="p-topHero__searchSubmitText" v-t="'検索'" />
                </button>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
    <PTopHeroSlider
      :has-video="hasVideo"
      :key="portalId"
      class="p-topHero__visual"
    />
  </div>
</template>

<script>
import PTopHeroVideo, {
  HasHeroVideoPortals,
} from '@/components/top/PTopHeroVideo.vue'
import PTopHeroSlider from '@/components/top/PTopHeroSlider.vue'

export default {
  name: 'PTopHero',
  components: {
    PTopHeroVideo,
    PTopHeroSlider,
  },
  props: {
    initialKeyword: {
      type: String,
      default: '',
    },
    initialAddress: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      keyword: '',
      address: '',
    }
  },
  computed: {
    hasVideo() {
      return HasHeroVideoPortals.includes(this.portalId)
    },
  },
  mounted() {
    this.keyword = this.initialKeyword
    this.address = this.initialAddress
  },
  methods: {
    handleSubmitSearchForm(e) {
      e.preventDefault()
      if (
        this.keyword === this.initialKeyword &&
        this.address === this.initialAddress
      )
        return

      this.$emit('submit:searchForm', this.keyword, this.address)
    },
  },
}
</script>
<style type="text/css">
  .p-topHero--hasVideo--adjustment{
  grid-template-columns:1050px calc(100% -740px)
}
</style>