var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"p-topAboutTrustIndex"},[_c('div',{staticClass:"l-container"},[_c('h2',{directives:[{name:"t",rawName:"v-t",value:({
        path: '{trustIndex}とは？',
        args: {
          trustIndex: _vm.$t(`${_vm.portalId}.おみせのトラスト指数`),
        },
      }),expression:"{\n        path: '{trustIndex}とは？',\n        args: {\n          trustIndex: $t(`${portalId}.おみせのトラスト指数`),\n        },\n      }"}],staticClass:"p-topAboutTrustIndex__title"}),_c('p',{directives:[{name:"t",rawName:"v-t",value:(
        `${_vm.portalId}.お店と施設それぞれの衛生対策状況をパーセンテージで示す、おみせのトラスト独自の信頼性指数です。トラスト指数が高いほど、衛生対策の信頼性が高いお店となります。`
      ),expression:"\n        `${portalId}.お店と施設それぞれの衛生対策状況をパーセンテージで示す、おみせのトラスト独自の信頼性指数です。トラスト指数が高いほど、衛生対策の信頼性が高いお店となります。`\n      "}],staticClass:"p-topAboutTrustIndex__description content"}),_c('div',{staticClass:"p-topAboutTrustIndex__icons"},[_c('figure',{staticClass:"p-topAboutTrustIndex__icon"},[_c('PMultiColorIcon',{attrs:{"icon-name":"ventilation","color-set":_vm.colorSet}})],1),_c('figure',{staticClass:"p-topAboutTrustIndex__icon"},[_c('PMultiColorIcon',{attrs:{"icon-name":"disinfect","color-set":_vm.colorSet}})],1),_c('figure',{staticClass:"p-topAboutTrustIndex__icon"},[_c('PMultiColorIcon',{attrs:{"icon-name":"customer","color-set":_vm.colorSet}})],1),_c('figure',{staticClass:"p-topAboutTrustIndex__icon"},[_c('PMultiColorIcon',{attrs:{"icon-name":"distance","color-set":_vm.colorSet}})],1),_c('figure',{staticClass:"p-topAboutTrustIndex__icon"},[_c('PMultiColorIcon',{attrs:{"icon-name":"staff","color-set":_vm.colorSet}})],1),_c('figure',{staticClass:"p-topAboutTrustIndex__icon"},[_c('PMultiColorIcon',{attrs:{"icon-name":"restroom","color-set":_vm.colorSet}})],1),_c('figure',{staticClass:"p-topAboutTrustIndex__icon"},[_c('PMultiColorIcon',{attrs:{"icon-name":"certification","color-set":_vm.colorSet}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }