<template>
  <section class="p-topNotice">
    <div class="l-container">
      <h2 class="p-topNotice__title" v-t="'お知らせ'" />
      <p class="p-topNotice__description content">
        {{ this.noticeText }}
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PTopNotice',
  props: {
    noticeList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    noticeKey() {
      switch (this.$i18n.locale) {
        case 'en':
          return 'notice_en'
        default:
          return 'notice_ja'
      }
    },
    noticeText() {
      if (this.noticeList.length > 0) {
        return this.noticeList[0][this.noticeKey]
      } else {
        return ''
      }
    },
  },
}
</script>
