<template functional>
  <div>
    <div class="p-topResultStatus">
    <i18n class="p-topResultStatus" path="登録センサー"></i18n>
    <i18n class="p-topResultStatus__count" style="color: red" path="{sensorCount}台">
      <template v-slot:sensorCount>
        <span class="p-topResultStatus__number" v-text="props.sensorCount" />
      </template>
    </i18n>
    </div>
    <i18n tag="div" class="p-topResultStatus" path="検索結果{count}{range}">
      <template v-slot:count>
        <i18n class="p-topResultStatus__count" path="{count}件">
          <template v-slot:count>
            <span class="p-topResultStatus__number" v-text="props.total" />
          </template>
        </i18n>
      </template>
      <template v-slot:range>
        <small
          v-if="props.total > 0"
          class="p-topResultStatus__range"
          v-t="{
            path: '{start} - {end}件を表示',
            args: { start: props.start, end: props.end },
          }"
        />
      </template>
    </i18n>
  </div>
</template>

<script>
export default {
  name: 'PTopResultStasus',
  props: {
    total: {
      type: Number,
      default: 0,
    },
    start: {
      type: Number,
      default: 0,
    },
    end: {
      type: Number,
      default: 0,
    },
    sensorCount: {
      type: Number,
      default: 0
    }
  },
}
</script>
