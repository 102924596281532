<template>
  <article>
    <div class="p-topFV">
      <PTopHero
        :initial-keyword="searchKeyword"
        :initial-address="searchAddress"
        @submit:searchForm="handleSubmitSearchForm"
      />
      <template v-if="noticeList.length > 0">
        <PTopNotice :notice-list="noticeList" />
      </template>
      <PTopAboutTrustIndex />
    </div>
    <div class="p-topResult" ref="resultArea" tabindex="-1">
      <div class="l-container">
        <PTopResultStasus
          class="p-topResult__status"
          :total="filteredShopList.length"
          :start="resultStart + 1"
          :end="resultEnd"
          :sensorCount="sensorCountList.linkSensors"
        />
        <template v-if="hasResult">
          <PTopBadgeDescription class="p-topResult__description" />
          <ul class="p-topResult__list">
            <li
              class="p-topResult__item"
              v-for="(shop, index) in result"
              :key="index"
            >
              <PShopCard :shopData="shop" />
            </li>
          </ul>
          <CPagination
            class="p-topResult__pagination"
            :active-page="activePage"
            :pages="pages"
            :limit="5"
            :label="$t('検索結果のページネーション')"
            @update:activePage="handleUpdateActivePage"
          />
        </template>
        <p
          v-else
          class="p-topResult__empty content"
          v-t="
            `${portalId}.該当するお店が見つかりませんでした。検索条件を変更して再度お試しください。`
          "
        />
      </div>
    </div>
  </article>
</template>

<script>
import PTopHero from '@/components/top/PTopHero.vue'
import PTopAboutTrustIndex from '@/components/top/PTopAboutTrustIndex.vue'
import PTopResultStasus from '@/components/top/PTopResultStasus.vue'
import PTopBadgeDescription from '@/components/top/PTopBadgeDescription.vue'
import PShopCard from '@/components/common/PShopCard.vue'
import CPagination from '@/components/common/CPagination.vue'
import PTopNotice from '@/components/top/PTopNotice.vue'

const searchLocaleMap = {
  en: {
    shopName: 'shopNameEnglish',
    facilityName: 'facilityNameEnglish',
    address: 'addressEnglish',
  },
}

export default {
  name: 'IndexPresentation',
  inject: ['scrollTo'],
  components: {
    PTopHero,
    PTopAboutTrustIndex,
    PTopResultStasus,
    PTopBadgeDescription,
    PShopCard,
    CPagination,
    PTopNotice,
  },
  props: {
    shopList: {
      type: Array,
      default: () => [],
    },
    noticeList: {
      type: Array,
      default: () => [],
    },
    sensorCountList: {
      type: Object,
      default: {},
    }
  },
  computed: {
    searchKeyword() {
      return this.$route.query.keyword || ''
    },
    searchAddress() {
      return this.$route.query.address || ''
    },
    isAndSearch() {
      return this.searchKeyword !== '' && this.searchAddress !== ''
    },
    activePage() {
      const queryPage = Number(this.$route.query.page) || 1
      return Math.min(queryPage, this.pages)
    },
    currentLanguageShopList() {
      return this.$i18n.locale === 'ja'
        ? this.shopList
        : this.shopList.filter(({ hasEnglishPage }) => hasEnglishPage)
    },
    filteredShopList() {
      if (this.searchKeyword === '' && this.searchAddress === '') {
        return this.currentLanguageShopList
      }

      const searchLocale = searchLocaleMap[this.$i18n.locale]

      return this.currentLanguageShopList.filter((shop) => {
        const shopName = shop[searchLocale?.shopName || 'shopName'] || ''
        const facilityName =
          shop[searchLocale?.facilityName || 'facilityName'] || ''
        const address = shop[searchLocale?.address || 'address'] || ''

        if (this.isAndSearch) {
          return (
            (shopName.includes(this.searchKeyword) ||
              facilityName.includes(this.searchKeyword)) &&
            address.includes(this.searchAddress)
          )
        }

        return (
          (this.searchKeyword !== '' &&
            (shopName.includes(this.searchKeyword) ||
              facilityName.includes(this.searchKeyword))) ||
          (this.searchAddress !== '' && address.includes(this.searchAddress))
        )
      })
    },
    pages() {
      return Math.ceil(this.filteredShopList.length / 20)
    },
    resultStart() {
      return (this.activePage - 1) * 20
    },
    resultEnd() {
      return Math.min(this.activePage * 20, this.filteredShopList.length)
    },
    result() {
      return this.filteredShopList.slice(this.resultStart, this.resultEnd)
    },
    hasResult() {
      return this.filteredShopList.length > 0
    },
    resultArea() {
      return this.$refs.resultArea
    },
    resultEmptyMassagePath() {
      return `resultEmpty.${this.portalId}`
    },
  },
  methods: {
    handleSubmitSearchForm(keyword, address) {
      if (keyword === '' && address === '') {
        this.$router.push({})
      } else if (keyword !== '' && address !== '') {
        this.$router.push({
          query: {
            keyword,
            address,
          },
        })
      } else if (keyword !== '') {
        this.$router.push({
          query: {
            keyword,
          },
        })
      } else if (address !== '') {
        this.$router.push({
          query: {
            address,
          },
        })
      }
      this.scrollResultAreaTop()
    },
    handleUpdateActivePage(page) {
      this.$router.push({
        query: {
          ...this.$route.query,
          page,
        },
      })
      this.scrollResultAreaTop()
    },
    scrollResultAreaTop() {
      this.scrollTo(this.resultArea)
    },
  },
}
</script>
