<template>
  <div class="p-topHeroVideo">
    <div v-if="portalData.balloonSrc" class="p-topHeroVideo__balloon">
      <img :src="portalData.balloonSrc" :alt="portalData.balloonAlt || ''" />
    </div>
    <PTopHeroYouTubeSlider
      class="p-topHeroVideo__player"
      :videoIdList="portalData.videoIdList"
      size="16p9"
    />
  </div>
</template>

<script>
import PTopHeroYouTubeSlider from '../top/PTopHeroYouTubeSlider.vue'

const HeroVideoMap = {
  shops: {
    // balloonSrc: '/img/top/img_video_balloon.svg', // 吹き出し画像のURL
    // balloonAlt: '合計100企業が参加中！', // 吹き出し画像のalt属性
    videoIdList: [], // 動画IDを配列に格納する。2つ以上格納されている場合、スライダー表示になる
  },
}

export const HasHeroVideoPortals = Object.keys(HeroVideoMap)

export default {
  name: 'PTopHeroVideo',
  components: { PTopHeroYouTubeSlider },
  computed: {
    portalData() {
      return HeroVideoMap[this.portalId]
    },
  },
}
</script>
