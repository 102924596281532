<template>
  <ul class="p-topBadgeDescription">
    <i18n
      tag="li"
      v-for="badge in badges"
      :key="badge.name"
      class="p-topBadgeDescription__item"
      :path="badge.path"
    >
      <template v-slot:badge>
        <PShopBadge class="p-topBadgeDescription__badge" :badge="badge.name" />
      </template>
    </i18n>
  </ul>
</template>

<script>
import PShopBadge from '@/components/common/PShopBadge.vue'

export default {
  name: 'PTopBadgeDescription',
  components: {
    PShopBadge,
  },
  computed: {
    badges() {
      return [
        {
          path: `${this.portalId}.{badge}は、IoTセンサーによるリアルタイム状況を表示している店舗です。`,
          name: 'iotSensor',
        },
        {
          path: `${this.portalId}.{badge}は、第三者によるチェックを行っている店舗です。`,
          name: 'shopConfirm',
        },
      ]
    },
  },
}
</script>
