<template>
  <section class="p-topAboutTrustIndex">
    <div class="l-container">
      <h2
        class="p-topAboutTrustIndex__title"
        v-t="{
          path: '{trustIndex}とは？',
          args: {
            trustIndex: $t(`${portalId}.おみせのトラスト指数`),
          },
        }"
      />
      <p
        class="p-topAboutTrustIndex__description content"
        v-t="
          `${portalId}.お店と施設それぞれの衛生対策状況をパーセンテージで示す、おみせのトラスト独自の信頼性指数です。トラスト指数が高いほど、衛生対策の信頼性が高いお店となります。`
        "
      />
      <div class="p-topAboutTrustIndex__icons">
        <figure class="p-topAboutTrustIndex__icon">
          <PMultiColorIcon icon-name="ventilation" :color-set="colorSet" />
        </figure>
        <figure class="p-topAboutTrustIndex__icon">
          <PMultiColorIcon icon-name="disinfect" :color-set="colorSet" />
        </figure>
        <figure class="p-topAboutTrustIndex__icon">
          <PMultiColorIcon icon-name="customer" :color-set="colorSet" />
        </figure>
        <figure class="p-topAboutTrustIndex__icon">
          <PMultiColorIcon icon-name="distance" :color-set="colorSet" />
        </figure>
        <figure class="p-topAboutTrustIndex__icon">
          <PMultiColorIcon icon-name="staff" :color-set="colorSet" />
        </figure>
        <figure class="p-topAboutTrustIndex__icon">
          <PMultiColorIcon icon-name="restroom" :color-set="colorSet" />
        </figure>
        <figure class="p-topAboutTrustIndex__icon">
          <PMultiColorIcon icon-name="certification" :color-set="colorSet" />
        </figure>
      </div>
    </div>
  </section>
</template>

<script>
import PMultiColorIcon from '@/components/common/PMultiColorIcon.vue'
import { ShopMeasuresIconColorSet } from '@/components/shop/PShopMeasuresIcon.vue'

export default {
  name: 'PTopAboutTrustIndex',
  components: { PMultiColorIcon },
  computed: {
    colorSet() {
      return ShopMeasuresIconColorSet[this.portalId]
    },
    trustIndexLabelPath() {
      return `trustIndex.label.${this.portalId}`
    },
    trustIndexDescriptionPath() {
      return `trustIndex.description.${this.portalId}`
    },
  },
}
</script>
