import { requestCsv } from '@/assets/modules/request'
import { NoticeViewModel } from '@/assets/request/viewModel/NoticeViewModel'

/**
 * お知らせのCSVを取得する
 * @returns {Promise<import('@/assets/modules/request/Response').Response<import('@/assets/request/types/noticeList').NoticeList>>}
 */
export async function getNoticeCsv() {
  const response = await requestCsv.get(`/notice/notice.csv`)
  return response.toViewModel(NoticeViewModel)
}
