<template>
  <article class="p-shopCard">
    <app-link
      class="p-shopCard__inner"
      :to="{
        name: 'ShopDetail',
        params: { id: this.shopData.id },
      }"
      :title="`${facilityName} ${shopName}`"
      :aria-label="`${facilityName} ${shopName}`"
    >
      <div class="p-shopCard__visual">
        <CImage
          class="p-shopCard__image"
          :src="shopData.thumbnail"
          size="16p9"
        />
      </div>
      <div class="p-shopCard__content">
        <p class="p-shopCard__facilityName">{{ facilityName }}</p>
        <h3 class="p-shopCard__shopName">{{ shopName }}</h3>
        <ul class="p-shopCard__badgeList">
          <li v-if="shopData.isIotCheck" class="p-shopCard__badgeListItem">
            <PShopBadge
              class="p-shopCard__badge"
              badge="iotSensor"
              :alt="$t(`${portalId}.IoTセンサー導入店舗`)"
            />
          </li>
          <li
            v-if="shopData.isThirdPersonCheck"
            class="p-shopCard__badgeListItem"
          >
            <PShopBadge
              class="p-shopCard__badge"
              badge="shopConfirm"
              :alt="$t(`${portalId}.第三者によるチェックを行っている店舗`)"
            />
          </li>
        </ul>
        <section class="p-shopCard__trustIndex">
          <h4
            class="p-shopCard__trustIndexTitle"
            v-t="`${portalId}.おみせのトラスト指数`"
          />
          <CProgress
            class="p-shopCard__trustIndexProgress"
            :value="trustIndex"
          />
        </section>
      </div>
    </app-link>
  </article>
</template>

<script>
import CImage from '@/components/common/CImage.vue'
import PShopBadge from '@/components/common/PShopBadge.vue'
import CProgress from '@/components/common/CProgress.vue'

export default {
  name: 'PShopCard',
  components: {
    CImage,
    PShopBadge,
    CProgress,
  },
  props: {
    shopData: [Object, Boolean],
  },
  computed: {
    trustIndex() {
      return Math.min(100, this.shopData.trustIndex)
    },
    shopName() {
      let shopName
      switch (this.$i18n.locale) {
        case 'en':
          shopName = this.shopData.shopNameEnglish
          break
      }
      // 英語情報が存在しない場合、日本語情報にフォールバック
      return shopName || this.shopData.shopName
    },
    facilityName() {
      let facilityName
      switch (this.$i18n.locale) {
        case 'en':
          facilityName = this.shopData.facilityNameEnglish
          break
      }
      // 英語情報が存在しない場合、日本語情報にフォールバック
      return facilityName || this.shopData.facilityName
    },
  },
}
</script>
